<template>
  <div class="reservation">
    <v-row style="margin-top:40px;font-size:32px;">
      <v-col cols="3">
        <v-row>
          <v-col cols="2">
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" style="text-align:center;margin-top:12px;">
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-col>
          <v-col cols="2">
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="margin:-26px auto 2px auto">
      <v-col cols="8">
        <v-tabs
          v-model="tab"
          color="#4DBCB8"
          @change="changeRegion"
        >
          <v-tab
            v-for="area in areas"
            :key="area.name"
          >
            {{ area.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4" style="text-align:right;">
      </v-col>
    </v-row>
    <v-sheet height="560">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="#4DBCB8"
        type="month"
        locale="zhHant"
      >
        <template v-slot:day="{ day, month, year }" style="text-align:center">
          <v-tooltip bottom style="background-color:white" open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-show="month === thisMonth"
                v-bind="attrs"
                v-on="on"
                style="cursor:pointer"
                @click="goDetail(day, month, year)"
              >
                <v-progress-linear
                  style="width:90%;margin:auto;border:1px solid #4DBCB8"
                  v-if="find(day).finished === 0 "
                  :value="find(day).finished"
                  height="25"
                  color="white"
                >
                  <strong>
                    0 %
                  </strong>
                </v-progress-linear>
                <v-progress-linear
                  style="width:90%;margin:auto"
                  v-else-if="find(day).finished <= find(day).planed"
                  :value="Math.round(find(day).finished / find(day).planed * 10000) / 100.00"
                  height="25"
                  color="#4DBCB8"
                >
                  <strong>
                    {{Math.round(find(day).finished / find(day).planed * 10000) / 100.00}} %
                  </strong>
                </v-progress-linear>
                <v-progress-linear
                  style="width:90%;margin:auto;"
                  v-else
                  :value="100"
                  height="25"
                  color="red"
                >
                  <strong>
                    {{Math.round(find(day).finished / find(day).planed * 10000) / 100.00}} %
                  </strong>
                </v-progress-linear>
                <div style="width:100%;text-align:center">
                  <span>{{ find(day).finished }} / {{ find(day).planed }}</span>
                </div>
              </div>
            </template>
          </v-tooltip>
        </template>
      </v-calendar>
    </v-sheet>
    <v-dialog
      v-model="dialog"
      width="800"
    >
      <v-card style="padding:20px;">
        <v-row v-show="edit">
          <v-col cols="4">
            <span style="font-size:18px">指派任務</span>
          </v-col>
          <v-col cols="8">
            <div style="text-align:right;">
              <v-icon @click="taskEdit = false">
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row v-for="taskDetail in taskHead" :key="taskDetail.label">
          <v-col cols="1">
            <v-icon style="color:#4DBCB8">
              {{taskDetail.icon}}
            </v-icon>
          </v-col>
          <v-col cols="2">
            {{ taskDetail.label }}
          </v-col>
          <v-col cols="9">
            <v-select
              style="margin:-16px 0px"
              v-model="task[taskDetail.value]"
              :items="items"
              :label="task[taskDetail.value]"
              outlined
              :disabled="!edit"
            ></v-select>
            <!-- {{task[taskDetail.value]}} -->
          </v-col>
        </v-row>
        <div style="text-align:center;margin-top:20px;">
          <v-btn
            v-if="edit"
            style="width:220px;height:50px;"
            color="#4DBCB8"
            dark
            @click="finishEdit"
          >
            <span style="font-size:18px;">完成</span>
          </v-btn>
          <span v-else>
            <v-btn
              style="width:250px;height:50px;margin-right:60px;"
              color="#4DBCB8"
              dark
              @click="edit = true"
            >
              <span style="font-size:18px;">編輯</span>
            </v-btn>
            <v-btn
              style="width:250px;height:50px;"
              color="#4DBCB8"
              outlined
              @click="dialog = false"
            >
              <span style="font-size:18px;">關閉</span>
            </v-btn>
          </span>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="unbookDialog"
      width="1000"
    >
      <v-card style="padding:20px;">
        <v-tabs color="#4DBCB8" style="margin-bottom:10px;">
          <v-tab v-for="tab in unbookTab" :key="tab.label">
            {{ tab.label }}
          </v-tab>
        </v-tabs>
        <v-row>
          <v-col cols="5">
            <v-card
              style="margin-top:4px;"
              v-for="wait in waiting"
              :key="wait.task">
              <v-card-text style="font-size:18px;">
                <v-row>
                  <v-col cols="3">
                    <v-icon>mdi-clipboard-outline</v-icon>
                    任務
                  </v-col>
                  <v-col cols="9">
                    {{ wait.task }}
                    <div style="font-size:14px;">
                      預計工時{{wait.plan}}分鐘  積分{{wait.score}}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-icon>mdi-account</v-icon>
                    人員
                  </v-col>
                  <v-col cols="9">
                    {{ wait.member }}
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-spacer />
                  <v-col cols="4">
                    <v-btn style="width:100px;" outlined color="#4DBCB8">
                      指派
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <div style="text-align:center;margin-top:30px;" @click="unbookDialog = false">
              <v-btn style="width:120px;" color="#4DBCB8" dark>
                關閉
              </v-btn>
            </div>
          </v-col>
          <v-col cols="7">
            <v-card style="padding:20px">
              <v-row v-for="taskDetail in taskHead" :key="taskDetail.label">
                <v-col cols="1">
                  <v-icon style="color:#4DBCB8">
                    {{taskDetail.icon}}
                  </v-icon>
                </v-col>
                <v-col cols="4">
                  {{ taskDetail.label }}
                </v-col>
                <v-col cols="7">
                  <v-select
                    dense
                    style="margin:-12px 0px"
                    v-model="task[taskDetail.value]"
                    :items="items"
                    :label="task[taskDetail.value]"
                    outlined
                  ></v-select>
                  <!-- {{task[taskDetail.value]}} -->
                </v-col>
              </v-row>
              <div style="text-align:center;">
                <v-btn style="width:160px;" color="#4DBCB8" dark>
                  完成
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from '../db';
// @ is an alias to /src

export default {
  name: 'Reservation',
  components: {

  },
  data: () => ({
    items: [],
    waiting: [
      {
        task: '任務B',
        plan: 45,
        score: 2,
        member: '林師傅、王師傅',
      }, {
        task: '任務C',
        plan: 100,
        score: 5,
        member: '徐師傅、黃師傅',
      },
    ],
    unbookTab: [
      {
        label: '今日待指派任務',
        value: 'unbook',
      }, {
        label: '未結案',
        value: 'undone',
      },
    ],
    edit: false,
    taskHead: [
      {
        icon: 'mdi-city',
        label: '區域',
        value: 'region',
      }, {
        icon: 'mdi-clipboard-outline',
        label: '任務',
        value: 'task',
      }, {
        icon: 'mdi-account',
        label: '人員',
        value: 'member',
      }, {
        icon: 'mdi-clock',
        label: '開始時間',
        value: 'start',
      }, {
        icon: '',
        label: '結束時間',
        value: 'end',
      }, {
        icon: 'mdi-map-marker',
        label: '地點',
        value: 'location',
      }, {
        icon: 'mdi-car-estate',
        label: '路程積分',
        value: 'traffic',
      },
    ],
    task: {
      region: '北區',
      task: '安裝類 > 濾水器安裝 > 家用氣泡水 > YS-120(CUML35.3)',
      member: '陳師傅、張師傅',
      start: '6月23(星期四)',
      end: '6月23(星期四)',
      location: '台北市中正區',
      traffic: 5,
    },
    unbookDialog: false,
    dialog: false,
    events: {
      '2022-10-30': ['38', '40'],
    },
    newTask: {
      year: '2022',
      month: '',
      day: '',
      finished: 0,
      total: 1,
      workers: [],
    },
    totals: [],
    oldtotals: [
      {
        year: '2022',
        month: '9',
        day: '1',
        finished: 32,
        total: 32,
        workers: [
          {
            name: '王師傅',
            finished: 2,
            total: 8,
            percent: 25,
          }, {
            name: '李師傅',
            finished: 2,
            total: 8,
            percent: 25,
          }, {
            name: '陳師傅',
            finished: 9,
            total: 8,
            percent: 112.5,
          }, {
            name: '林師傅',
            finished: 5,
            total: 8,
            percent: 62.5,
          }, {
            name: '黃師傅',
            finished: 8,
            total: 8,
            percent: 100,
          },
        ],
      }, {
        year: '2022',
        month: '9',
        day: '2',
        finished: 28,
        total: 24,
      }, {
        year: '2022',
        month: '9',
        day: '3',
        finished: 36,
        total: 40,
      },
    ],
    tab: '',
    areas: [],
    focus: '',
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    thisYear: '',
    thisMonth: '',
  }),
  mounted() {
    this.getAreas();
    this.$refs.calendar.checkChange();
    this.thisYear = this.$refs.calendar.times.today.year;
    this.thisMonth = this.$refs.calendar.times.today.month;
    if (this.$store.state.userInfo.region !== '總部') {
      this.$store.state.region = this.$store.state.userInfo.region;
      this.tab = this.areas.findIndex((ele) => ele.name === this.$store.state.userInfo.region);
    }
    // console.log(this.getDaysInMonth(this.thisYear, this.thisMonth), '^&^&^&^&^&^&');
    // this.getRegionEvents(this.thisYear, this.thisMonth);
    this.getMonthTasks(this.$store.state.userInfo.region);
  },
  methods: {
    getAreas() {
      const docRef = db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g');
      docRef.get().then((doc) => {
        if (doc.exists) {
          doc.data().areas.forEach((area, index) => {
            this.$set(this.areas, index, {
              name: area.name,
            });
          });
          // this.readTaskFromTasks(doc.data().tasks);
          console.log('mounte areas document data:', doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });
    },
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    getMonthTasks(region) {
      const monthLong = this.getDaysInMonth(this.thisYear, this.thisMonth);
      const monthStart = new Date(this.thisYear, this.thisMonth - 1);
      // console.log(this.thisYear, this.thisMonth - 1, '&*&*&*&*&*&*&*&*&*&*&');
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
        .where('start', '>=', monthStart.getTime() - 28800000)
        .where('start', '<', monthStart.getTime() + 86400000 * monthLong - 28800000)
        .where('region', '==', region)
        .where('actived', '==', true)
        .onSnapshot(async (snapshot) => {
          console.log(this.thisYear, 'fire changeeeeeeeeeeee', this.thisMonth - 1);
          this.$set(this, 'totals', []);
          await snapshot.forEach((change) => {
            const year = (new Date(change.data().start)).getFullYear();
            const month = (new Date(change.data().start)).getMonth() + 1;
            const date = (new Date(change.data().start)).getDate();
            const found = this.totals.find((ele) => ele.day === date);
            if (found === undefined) {
              this.totals.push({
                year,
                month,
                day: date,
                planed: 1,
                finished: change.data().finished === undefined ? 0 : 1,
                workers: change.data().member.length,
                taskAmount: 1,
              });
            } else {
              found.planed += 1;
              found.workers += change.data().member.length;
              found.taskAmount += 1;
              if (change.data().finished !== undefined) {
                found.finished += 1;
              }
            }
            console.log(year, month, date, ':::::::::::', found);
          });
        });
    },
    getRegionEvents(year, month) {
      // const todayTime = new Date(this.$store.state.currentDate);
      // const todayTime = new Date(this.$refs.calendar.times.today.date);
      // this.thisYear = this.$refs.calendar.times.today.year;
      // this.thisMonth = this.$refs.calendar.times.today.month;
      // const monthStart = new Date(this.thisYear, this.thisMonth - 1);
      // const monthLong = new Date(this.thisYear, this.thisMonth, 0).getDate();
      console.log(year, month, '*****');
      // db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('events')
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('dateInfo')
        .where('year', '==', year)
        .where('month', '==', month)
        // .where('start', '>=', monthStart.getTime() - 28800000)
        // .where('start', '<', monthStart.getTime() + 86400000 * monthLong - 28800000)
        .onSnapshot(async (snapshot) => {
          await snapshot.forEach((change) => {
            this.totals.push({
              year: change.data().year,
              month: change.data().month,
              day: change.data().date,
              planed: change.data().planed,
              finished: change.data().finished,
              workers: change.data().workers,
              taskAmount: change.data().taskAmount,
            });
            // const newTime = new Date(change.data().start);
            console.log(change.data(), 'KLKLKKKKKKKKKK');
          });
        });
    },
    async goDetail(day, month, year) {
      this.$set(this.$store.state, 'currentDate', new Date(year, (month - 1), day));
      // this.$store.state.currentDate = new Date(year, month, day);
      // this.$router.push('/detail');
      const newMonth = month.toString().padStart(2, '0');
      const newDay = day.toString().padStart(2, '0');
      await this.$set(this.$store.state, 'region', this.areas[this.tab].name);
      await this.$router.push(`/detail/${year}${newMonth}${newDay}`);
      // this.$set(this.$store.state, 'region', this.areas[this.tab].name);
      console.log(this.$store.state.region, '%%%%%%%%%%%%');
    },
    find(day) {
      let result = '';
      if (this.totals.length > 0) {
        const found = this.totals.find((ele) => ele.day.toString() === day.toString());
        // console.log(day, found, '{}{}{}{}{}{}{}{}');
        if (found === undefined) {
          result = { finished: 0, planed: 0 };
        } else {
          result = { finished: found.finished, planed: found.planed };
        }
      } else {
        result = { finished: 0, planed: 0 };
      }
      return result;
    },
    oldfind(day) {
      let result = '';
      // if (day < 4) {
      //   result = this.totals.find((ele) => ele.day === day.toString());
      // } else {
      //   result = { finished: 0, total: 0 };
      // }
      if (this.totals.length > 0) {
        const found = this.totals.find((ele) => ele.day.toString() === day.toString());
        if (found === undefined) {
          result = { finished: 0, planed: 0 };
        } else {
          const regionFound = found.taskAmount.find((el) => el.region === this.$store.state.region);
          // console.log(regionFound, '?????');
          if (regionFound === undefined) {
            found.planed = 0;
            found.finished = 0;
          } else {
            found.planed = regionFound.amount;
            found.finished = regionFound.finished;
          }
          result = found;
          // this.$set(found, 'planed', regionFound.amount)
        }
        // let regionFound = result.taskAmount.find((el) => el.region === this.$store.state.region)
        // this.$set(result, 'planed', regionFound.amount)
      } else {
        result = { finished: 0, planed: 0 };
      }
      // console.log(day, '########', this.totals);
      return result;
    },
    async changeRegion() {
      this.totals = await [];
      await this.getMonthTasks(this.areas[this.tab].name);
    },
    async prev() {
      this.totals = [];
      // this.thisMonth = this.$refs.calendar.value.slice(5, 7);
      const monthLong = new Date(this.thisYear, this.thisMonth, 0).getDate();
      await this.$refs.calendar.prev();
      await console.log(this.$refs.calendar.lastStart, 'PPPP', monthLong);
      // const year = await this.$refs.calendar.lastStart.year;
      const month = await this.$refs.calendar.lastStart.month;
      this.thisMonth = await month;
      await this.$set(this, 'thisYear', Number(this.$refs.calendar.value.slice(0, 4)));
      await console.log(this.thisMonth, this.$refs.calendar.value, '^&^&^&^&^&^&');
      // await this.getRegionEvents(year, month);
      await this.getMonthTasks(this.areas[this.tab].name);
    },
    async next() {
      this.totals = [];
      this.thisYear = this.$refs.calendar.value.slice(0, 4);
      this.thisMonth = this.$refs.calendar.value.slice(5, 7);
      const monthLong = new Date(this.thisYear, this.thisMonth, 0).getDate();
      console.log(this.$refs.calendar.value, 'NNNN', monthLong);
      await this.$refs.calendar.next();
      // const year = await this.$refs.calendar.lastStart.year;
      const month = await this.$refs.calendar.lastStart.month;
      this.thisMonth = await month;
      // this.getRegionEvents(year, month);
      await this.getMonthTasks(this.areas[this.tab].name);
    },
  },
};
</script>
